/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const barberHero = {
  title: "Barber shop POS system & scheduling software",
  subtext:
    "Increase sales with more appointments, fewer no-shows, and loyal customers who keep coming back. SpotOn’s end-to-end payments and business management platform for barbers will take you to the next level.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/barber/demo",
  },
};
export const barberSubHero = {
  title: "Tech for keeping your chairs full",
  list: [
    "Book more appointments, even when your shop is closed",
    "Attract new clients with a strong online presence",
    "Improve client retention and drive revenue growth",
    "Put more money in your pocket with more ways to get paid",
  ],
  subtext:
    "There’s so much more to running a barbershop than cutting hair. SpotOn will help you attract new clients online, give them easier ways to book appointments, and help you keep them coming back, so your business grows but your time spent on admin doesn’t.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/barber/demo",
  },
};

export const barberWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Work with a team that’s as passionate as you are",
  content:
    "You make sure your clients always look good. Let us do the same for you. Our team will work closely with you to customize a software and payment solution that makes sense for your barbershop. And we’ll continue to be there for you with local service and 24/7 technical support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/barber/demo",
  },
};

export const barberLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Save time. Increase sales",
      blockSubTitle:
        "Ditch the mismatched tools you’ve been using for calendaring, payments, and online marketing. SpotOn simplifies how you do business with technology that’s powerful yet easy to use. Our cloud-based barbershop software is designed to streamline time-intensive tasks, while creating a topnotch client experience that will help you grow your business. ",
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/barber/demo",
      },
    },
  ],
};

export const barberTrusted = [
  {
    title: "30 – 40%",
    subtext: "Increase in sales with online booking",
  },
  {
    title: "42%",
    subtext: "Clients prefer to schedule online",
  },
  {
    title: "31%",
    subtext: "Increase in sales with repeat clients",
  },
  {
    title: "40%",
    subtext: "decrease in no-shows with automated reminders",
  },
];

export const barberMultiSection = {
  sectionTitle: "Smarter tools to run & grow your barbershop",
  featureBlocks: [
    {
      blockTitle: "Book more appointments & reduce no-shows",
      blockSubTitle:
        "Free up time with software that takes care of team scheduling and booking appointments. With online appointment booking from your website and Facebook page, interactive calendaring, text and email reminders, and no-show protection, you can focus on your clients. Let us do the rest.",
      blockImg: "barber-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/barber/demo",
      },
    },
    {
      blockTitle: "Taps, dips, swipes—we’ve got you covered",
      blockSubTitle:
        "Make the checkout process easy for you and your clients with the sleek SpotOn Terminal. In addition to taking various types of payments, it’s fully integrated with SpotOn Marketing and Loyalty so your clients can easily redeem digital coupons and loyalty rewards during checkout, helping you improve client retention and boost revenue.",
      blockList: [
        "Apple Pay, Google Pay, and NFC enabled",
        "Wi-Fi and 4G connectivity",
        "Built-in receipt printer and scanner",
        "Customer email capture to grow your mailing list",
        "Cash discount and surcharge ready",
      ],
      blockImg: "barber-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/barber/demo",
      },
    },
    {
      blockTitle: "Reward your best customers and boost sales",
      blockSubTitle:
        "Improve customer loyalty and revenue with a simple loyalty program that can be customized for your barbershop to encourage repeat visits. A free shave after 3 haircuts? No problem. A few clicks and you’re ready to go.",
      blockImg: "barber-c.png",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/barber/demo",
      },
    },
    {
      blockTitle: "Stay connected with simple marketing tools",
      blockSubTitle:
        "Stay in touch with clients, whether it’s to announce new services, offer discounts, or simply say thank you. SpotOn makes it quick and easy with a simple campaign wizard you can run from any computer or even your mobile phone. You’ll be able to send branded emails and deals quickly and easily to every client who has booked an appointment or enrolled in your loyalty program.",
      blockImg: "barber-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/barber/demo",
      },
    },
    {
      blockTitle: "Stand out from the competition with a slick new website",
      blockSubTitle:
        "Attract more clients with a professional website that will help your barbershop show up higher in local searches. Our specialists will build it for you at a do-it-yourself price.",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom website name",
        "Online appointment booking",
        "Search engine optimization",
        "Self-service dashboard & lifetime support",
      ],
      blockImg: "barber-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/barber/demo",
      },
    },
  ],
};

export const barberCTA = {
  title: "Sign up for your demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/barber/demo",
  },
};

export const barberTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const barberArticles = {
  title: " Expert articles & advice on growing your business",
  bgText: "Articles.",
};

export const hairCar = {
  // Uncomment the following line and remove
  // the other title once we add more than one vertical
  // title: 'We know health & beauty',
  title: "We know health & beauty",
  subtext:
    "Our appointment and scheduling software is great for all kinds of salons, spas, and health & beauty providers.",
  slides: [
    {
      bg: "hair-salon.png",
      cardTitle: "Hair salon",
      cardContent: "More appointments, fewer no-shows",
      icon: "hair-salon.png",
      linkTo: "/health-and-beauty/hair-salons",
    },
    {
      bg: "nail-salon.png",
      cardTitle: "Nail salons",
      cardContent: "Increase sales and get paid fast",
      icon: "nail-salon.png",
      linkTo: "/health-and-beauty/nail-salons",
    },
    {
      bg: "tanning.png",
      cardTitle: "Tanning salons",
      cardContent: "Increase sales and get paid fast",
      icon: "tanning.png",
      linkTo: "/health-and-beauty/tanning-salon",
    },
    {
      bg: "dentist.png",
      cardTitle: "Dentists",
      cardContent: "Increase sales and get paid fast",
      icon: "dentist.png",
      linkTo: "/health-and-beauty/dentist",
    },
  ],
};
